// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Store from './pages/Store';
import Games from './pages/Games';
import ProductDetails from './pages/ProductDetails';
import NotFound from './pages/NotFound'; // اضافه کردن صفحه 404
import './App.css';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login'; // اضافه کردن صفحه ورود
import Register from './components/Register'; 


// ...

const App = () => {
    return (
        <AuthProvider>
            <div className="app-container">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/product/:productId" element={<ProductDetails />} />
                    <Route path="/store" element={<Store />} />
                    <Route path="/games" element={<Games />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </div>
        </AuthProvider>
    );
};

export default App;


