// src/services/AuthService.js
import axios from 'axios';

// const API_URL = 'http://localhost:5000'; // آدرس API فلاسک

export const register = async (username, email, password) => {
    const response = await axios.post(`/register`, { username, email, password });
    return response.data;
};

export const login = async (username, password) => {
    const response = await axios.post(`/login`, { username, password });
    return response.data;
};

export const isLoggedIn = () => {
    return !!localStorage.getItem('access_token'); // بررسی وضعیت لاگین
};

export const logout = () => {
    localStorage.removeItem('access_token'); // خروج کاربر
};
