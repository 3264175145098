import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Comment.css'; // استایل‌های مربوط به کامنت‌ها
import './ProductDetails.css'; // استایل‌های مربوط به جزئیات محصول

const ProductDetails = () => {
    const { productId } = useParams(); // دریافت شناسه محصول از URL
    const [product, setProduct] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState({ user_name: '', comment: '' });

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`/api/products/${productId}`);
                const data = await response.json();
                
                setProduct(data);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        const fetchComments = async () => {
            try {
                const response = await fetch(`/api/products/${productId}/comments`);
                const data = await response.json();
                console.log(`test comeent ${data}`)
                setComments(data);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchProductDetails();
        fetchComments();
    }, [productId]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!newComment.user_name || !newComment.comment) return;

        try {
            const response = await fetch(`/api/products/${productId}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newComment),
            });

            if (response.ok) {
                setComments((prev) => [...prev, { user_name: newComment.user_name, comment: newComment.comment }]);
                setNewComment({ user_name: '', comment: '' }); // Resetting the form
            } else {
                console.error('Error submitting comment:', await response.json());
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!product) return <p>در حال بارگذاری جزئیات محصول...</p>;

    return (
        <div className="product-details">
            <img src={product.image} alt={product.name} className="product-image" />
            <h1>{product.name}</h1>
            <p className="price">قیمت: {product.price} تومان</p>
            <p>{product.description}</p>
            <h2>تعداد خرید: {product.purchase_count}</h2>
            <h3>کامنت‌ها:</h3>
            <ul>
                {comments.map((comment, index) => (
                    <li key={index}>
                        <strong>{comment.user_name}</strong>: {comment.comment}
                    </li>
                ))}
            </ul>
            <form onSubmit={handleCommentSubmit}>
                <input
                    type="text"
                    placeholder="نام کاربر"
                    value={newComment.user_name}
                    onChange={(e) => setNewComment({ ...newComment, user_name: e.target.value })}
                    required
                />
                <textarea
                    placeholder="کامنت"
                    value={newComment.comment}
                    onChange={(e) => setNewComment({ ...newComment, comment: e.target.value })}
                    required
                />
                <button type="submit">ارسال کامنت</button>
            </form>
        </div>
    );
};

export default ProductDetails;
