import React, { useEffect, useState, useCallback,useContext  } from 'react';
import './Store.css'; // اطمینان حاصل کنید که این CSS برای استایل‌دهی محصولات موجود است
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';
const Store = () => {
    const [products, setProducts] = useState([]); // لیست محصولات
    const [loading, setLoading] = useState(false); // وضعیت بارگذاری
    const [page, setPage] = useState(1); // صفحه فعلی
    const [hasMore, setHasMore] = useState(true); // آیا صفحه بعدی وجود دارد؟
    const { isAuthenticated } = useContext(AuthContext);

    // تابع بارگذاری محصولات
    const loadProducts = useCallback(async () => {
        if (loading || !hasMore) return; // جلوگیری از بارگذاری هم‌زمان یا بارگذاری بیهوده

        setLoading(true); // شروع بارگذاری
        try {
            const token = localStorage.getItem('access_token'); // توکن را بگیرید، اگر وجود داشته باشد
    
            // ساخت هدر برای درخواست
            const headers = {
                'Content-Type': 'application/json', // نوع محتوا
            };
            
            // اگر توکن موجود است، آن را به هدر اضافه کنید
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
    
            // ارسال درخواست به API
            const response = await fetch(`/api/products/popular?page=${page}`, {
                method: 'GET',
                headers: headers,  // هدر ارسال می‌شود
            });
            const data = await response.json();

            if (response.ok) {
                setProducts((prev) => [...prev, ...data.products]); // افزودن محصولات جدید
                setHasMore(data.has_next); // بررسی اینکه آیا صفحه بعدی وجود دارد
            } else {
                console.error('Error fetching products:', data);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(true); // پایان بارگذاری
        }
    }, [page, hasMore, loading]);

    // بارگذاری اولیه محصولات
    useEffect(() => {
        loadProducts(); // بارگذاری محصولات هنگام ورود به صفحه
    }, [loadProducts]);

    // بارگذاری محصولات با کلیک دکمه
    const loadMoreProducts = () => {
        if ( hasMore) {
            setLoading(false)
            setPage((prev) => prev + 1); // افزایش شماره صفحه
            loadProducts(); // بارگذاری محصولات
        }
    };
    const handleLike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!'); // نمایش پیغام برای کاربر
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/like`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // ارسال توکن در هدر
                'Content-Type': 'application/json', // نوع محتوا
            },
        });
        const data = await response.json();
        if (response.ok) {
            setProducts((prevProducts) =>
                prevProducts.map((product) => 
                    product.id === productId ? {
                        ...product,
                        likes: data.likes,
                        dislikes: data.dislikes,
                        likedByUser: !product.likedByUser, // تغییر وضعیت لایک
                        dislikedByUser: false // دیس‌لایک باید به false برگردد
                    } : product
                )
            );
        } else {
            console.error('Error liking product:', data);
        }
    };
    
    const handleDislike = async (productId) => {
        if (!isAuthenticated) {
            alert('لطفاً ابتدا وارد حساب کاربری خود شوید!'); // نمایش پیغام برای کاربر
            return;
        }
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/products/${productId}/dislike`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // ارسال توکن در هدر
                'Content-Type': 'application/json', // نوع محتوا
            },
        });
        const data = await response.json();
        if (response.ok) {
            console.log(data)
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === productId ? {
                        ...product,
                        dislikes: data.dislikes,
                        likes: data.likes,
                        dislikedByUser: !product.dislikedByUser, // تغییر وضعیت دیس‌لایک
                        likedByUser: false // لایک باید به false برگردد
                    } : product
                )
            );
        } else {
            console.error('Error disliking product:', data);
        }
    };
    
    
    
  return (
    <div className="store">
        <h1>محصولات محبوب</h1>
        <div className="product-list">
            {products.length > 0 ? (
                products.map((product) => (
                    <div key={product.id} className="product-card">
                        <Link to={`/product/${product.id}`} >
                            <img src={product.image} alt={product.name} />
                            <h2>{product.name}</h2>
                        </Link>
                        <p className="price">قیمت: {product.price} تومان</p>
                        <div className="likes-dislikes">
                            <span onClick={() => handleLike(product.id)} style={{ color: product.likedByUser ? '#4CAF50' : '#000' }}>
                                <i className="fas fa-thumbs-up"></i> {product.likes}
                            </span>
                            <span onClick={() => handleDislike(product.id)} style={{ color: product.dislikedByUser ? '#f44336' : '#000' }}>
                                <i className="fas fa-thumbs-down"></i> {product.dislikes}
                            </span>
                        </div>


                        <button className="buy-button">خرید</button>
                    </div>
                ))
            ) : (
                <p>در حال بارگذاری محصولات...</p>
            )}
        </div>
        {loading && <p>در حال بارگذاری...</p>}
        {!hasMore && <p>محصولی دیگر وجود ندارد.</p>}

        {/* دکمه بارگذاری بیشتر */}
        {hasMore && (
            <div className="load-more" onClick={loadMoreProducts}>
                <i className="fas fa-plus-circle"></i> {/* آیکون بارگذاری */}
            </div>
        )}
    </div>
);
}

export default Store;
