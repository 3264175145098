import React from 'react';
import './Home.css';
// import heroImage from '../assets/images/hero_image.png';

const Home = () => {
  return (
    <section className="home">
      <h2>به سیمرغ خوش آمدید</h2>
      {/* <img src={heroImage} alt="تصویر سیمرغ" className="hero-image" /> */}
      <p>در اینجا می‌توانید به بازی‌ها بپیوندید و محصولات را خریداری کنید.</p>
    </section>
  );
};

export default Home;
